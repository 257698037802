<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="12">
        <label class="title-step">
          {{ $t('components.form.playground.block-price.step-0.booking-options.title') }}
        </label>
      </b-col>
    </b-row>
    <b-row
      v-if="!isLoadingOptions"
      :class="optionsSectionClass"
    >
      <template v-for="(option, key) of options">
        <b-col :key="'option-' + key" cols="4">
          <div class="d-flex flex-row align-content-center align-items-center">
            <vs-checkbox
              v-model="option.checked"
              color="primary"
              @change.stop="onOptionCheck(option)"
            >
            </vs-checkbox>
            <span class="ml-3 mt-2">
              <label>
                {{ option.name }}
              </label>
            </span>
          </div>
        </b-col>
      </template>
    </b-row>
    <b-row v-else>
      <b-col class="d-flex align-items-center align-content-center justify-content-center">
        <d-custom-loader/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {LEISURE_TYPE} from "@/classes/doinsport/Price";

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    isLoadingOptions: {
      type: Boolean,
      default: false,
    },
    blockPrice: {
      type: Object,
      default: () => {}
    },
  },
  computed: {
    optionsSectionClass () {
      return this.blockPrice.activityType === LEISURE_TYPE ? 'scroll-leisure-options' : 'scroll-sport-options';
    }
  },
  methods: {
    onOptionCheck(option) {
      this.$emit('on:option:check', option);
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/form/_block-price.scss";
.scroll-leisure-options {
  max-height: 210px;
  overflow-y: scroll;
}
.scroll-sport-options {
  max-height: 320px;
  overflow-y: scroll;
}
</style>
